/* App.css */

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.content {
  padding: 20px;
}

.nav {
  display: flex;
  justify-content: left;
  border-bottom: 2px solid #07b2f6;
}

.tab-style {
  border: 1px solid #07b2f6;
 
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.tab-active .nav-item {
  background-color: rgb(148, 207, 230);
  
  font-weight: bold;
  color: white;
}

.tab-active .nav-item:hover {
  background-color: #ffffff;
}

.inline-photo {
  border: 1em solid #fff;
  border-bottom: 4em solid #fff;
  border-radius: .25em;
  box-shadow: 1em 1em 2em .25em rgba(0, 0, 0, .2);
  margin: 2em auto;
  /* opacity: 0; */
  transform: translateY(4em) rotateZ(-5deg);
  /* transition: transform 4s .25s cubic-bezier(0, 1, .3, 1),
    opacity .3s .25s ease-out; */
  max-width: 600px;
  width: 90%;
  
}